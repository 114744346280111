import React from "react";
import { useTranslation } from "next-i18next";
import { TextField } from "@mui/material";
import Button, { ButtonVariant } from "../button/button";
import FormAgreement from "../formAgreement/formAgreement";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import settings from "../../settings";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { mutateContactUs } from "../../api/mutations/contactUs";
import CircularProgress from "@mui/material/CircularProgress";

export const formSchema = z.object({
  yourEmail: z.string().email(),
});

const DownloadForm: React.FC = () => {
  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isSuccess, isError, isLoading } =
    useMutation(mutateContactUs);
  const { t } = useTranslation();
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const onSubmit = async (data: any) => {
    if (recaptchaRef && recaptchaRef.current) {
      const recaptchaCode = await recaptchaRef.current.executeAsync();
      if (recaptchaCode) {
        const payload = {
          sender: undefined,
          subject: "Android version notification",
          body: `Please notify ${data.yourEmail} when android version will be launched. \nThis mail was sent from SureHand website.`,
          reCaptcha: recaptchaCode,
        };
        await mutate(payload);
      }
      await recaptchaRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("contactForm.success"));
      reset({
        yourEmail: "",
      });
    }
  }, [isSuccess, reset]);

  React.useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("contactForm.error"));
    }
  }, [isError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={settings.recaptchaKey}
      />
      <FormControl component="fieldset" variant="standard" fullWidth>
        <Controller
          name="yourEmail"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                label={t("download.form.yourEmail")}
                variant="filled"
                error={fieldState.invalid}
                fullWidth
                helperText={fieldState.error?.message}
              />
            );
          }}
        />
        <Box mb={2} />
      </FormControl>
      <FormAgreement />
      <Box display="flex">
        {isLoading ? (
          <Box sx={{ display: "flex", height: 50 }} alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Button variant={ButtonVariant.primary} type="submit">
            {t("download.form.notifyMe")}
          </Button>
        )}
      </Box>
    </form>
  );
};

export default DownloadForm;
