import React from "react";
import styled from "@emotion/styled";
import { Trans, useTranslation } from "next-i18next";
import GetApp, { StoreType } from "../../components/getApp/getApp";
import Paper from "../../components/paper/paper";
import breakpoints from "../../helpers/breakpoints";
import Heading1, { Heading1Type } from "../typo/heading1/heading1";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import Button, { ButtonVariant } from "../button/button";
import Link from "next/link";
import FormAgreement from "../formAgreement/formAgreement";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import DownloadForm from "../downloadForm/downloadForm";

const Root = styled.div`
  margin: 0 0 45px 0;
  padding-top: 65px;
  @media (min-width: ${breakpoints.min.lg}) {
    padding-top: 130px;
    margin: 0 0 90px 0;
  }
`;

const MarginedTop = styled.div`
  margin-top: 65px;
  @media (min-width: ${breakpoints.min.lg}) {
    margin-top: 130px;
  }
`;

const Download: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-5">
            <Heading1 as="h2">{t("download.title")}</Heading1>
            <div className="mb-3" />
            <div>
              <GetApp storeType={StoreType.AppStore} />
            </div>
          </div>
          <div className="offset-lg-1 col-lg-3 d-none d-lg-block">
            <Paper large>
              <img src="/images/qr.png" alt="QR code" className="img-fluid" />
            </Paper>
          </div>
        </div>
        <MarginedTop>
          <div className="row">
            <div className="offset-lg-2 col-lg-3 d-none d-lg-block">
              <Paper large>
                <img
                  src="/images/android.png"
                  className="img-fluid"
                  alt="Android"
                />
              </Paper>
            </div>
            <div className="offset-lg-1 col-lg-5">
              <div className="A-anchor" id="android-version"></div>
              <Heading1 as={"h2"} type={Heading1Type.greyed}>
                {t("download.android.title")}
              </Heading1>
              <Paragraph size={ParagraphSize.small}>
                {t("download.android.text")}
              </Paragraph>
              <DownloadForm />
            </div>
          </div>
        </MarginedTop>
      </div>
    </Root>
  );
};

export default Download;
