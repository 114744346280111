import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import Heading1 from "../typo/heading1/heading1";
import Paragraph from "../typo/paragraph/paragraph";
import breakpoints from "../../helpers/breakpoints";
import NewFunctionalitiesForm from "../newFunctionalitiesForm/newFunctionalitiesForm";

const Root = styled.div`
  padding-top: 20px;
  margin: 20px 0 40px;
  @media (min-width: ${breakpoints.min.lg}) {
    padding-top: 40px;
    margin: 35px 0 75px;
  }
`;

const NewFunctionalities: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="A-anchor" id="new-functionalities"></div>
            <Heading1 as="h2">{t("newFunctionalities.title")}</Heading1>
            <div className="mb-3" />
            <Paragraph>{t("newFunctionalities.description")}</Paragraph>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <NewFunctionalitiesForm />
          </div>
        </div>
      </div>
    </Root>
  );
};

export default NewFunctionalities;
