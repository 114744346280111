import React from "react";
import { Trans } from "next-i18next";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";

const FormAgreement: React.FC = () => {
  return (
    <Paragraph size={ParagraphSize.small}>
      <Trans i18nKey={"form.agreement"}>
        textBefore
        <a
          href="https://surehand.digital/src/files/Information_on_the_processing_of_personal_data_EN.pdf"
          target="_blank"
        >
          textInside
        </a>
        .
      </Trans>
    </Paragraph>
  );
};

export default FormAgreement;
