import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import styleVariables from "../../../helpers/styleVariables";
import breakpoints from "../../../helpers/breakpoints";

interface Heading3Props {
  greyed?: boolean;
  className?: string;
}

type Heading3StylesProps = Pick<Heading3Props, "greyed">;

const Root = styled.h3<Heading3StylesProps>`
  font-size: ${styleVariables.fontSize.heading3FontSize};
  color: ${(props) =>
    props.greyed
      ? styleVariables.colors.grey
      : styleVariables.colors.blueRegular};
  font-weight: normal;
  line-height: 1.33;
`;

const Heading3: React.FC<Heading3Props> = ({ className, greyed, children }) => {
  return (
    <Root greyed={greyed} className={className}>
      {children}
    </Root>
  );
};

export default Heading3;
