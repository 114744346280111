import React from "react";
import { useTranslation } from "next-i18next";
import Avatar from "../avatar/avatar";
import Heading1 from "../typo/heading1/heading1";
import styled from "@emotion/styled";
import breakpoints from "../../helpers/breakpoints";
import Heading3 from "../typo/heading3/heading3";
import Paragraph from "../typo/paragraph/paragraph";

interface TeamMember {
  imageSrc: string;
  name: string;
  description: string[];
}

const Root = styled.div`
  padding-top: 20px;
  margin: 24px 0 44px;
  @media (min-width: ${breakpoints.min.lg}) {
    padding-top: 40px;
    margin: 44px 0 88px;
  }
  display: block;
`;

const ourTeam: React.FC = () => {
  const { t } = useTranslation();
  const data: TeamMember[] = [
    {
      imageSrc: "/images/novak.jpg",
      name: t("ourTeam.novak.name"),
      description: [t("ourTeam.novak.description")],
    },
    {
      imageSrc: "/images/trigama.svg",
      name: t("ourTeam.trigama.name"),
      description: [
        t("ourTeam.trigama.description1"),
        t("ourTeam.trigama.description2"),
      ],
    },
  ];

  return (
    <Root>
      <div className="container text-center">
        <div className="row">
          <div className="col-md-11 offset-md-1 mb-4 pb-2">
            <Heading1 as="h2" className="text-md-left">
              {t("ourTeam.title")}
            </Heading1>
          </div>
          {data.map(({ imageSrc, name, description }, index) => {
            return (
              <div
                key={name}
                className={`col-md-4 offset-md-1 ${
                  index === data.length - 1 ? "offset-md-2" : "mb-4"
                }`}
              >
                <div className="text-center mx-auto mx-md-0 justify-content-center d-flex d-md-block">
                  <Avatar imgSrc={imageSrc} large={true} imgAlt={name} />
                </div>
                <Heading3 className="text-left my-3">{name}</Heading3>
                {description.map((paragraph) => (
                  <Paragraph key={paragraph} className="text-left mb-2">
                    {paragraph}
                  </Paragraph>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Root>
  );
};

export default ourTeam;
