import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import GetApp, { StoreType } from "../../components/getApp/getApp";
import Paper from "../paper/paper";
import Heading1 from "../typo/heading1/heading1";
import Paragraph from "../typo/paragraph/paragraph";
import breakpoints from "../../helpers/breakpoints";
import FeedbackForm from "../feedbackForm/feedbackForm";

const Root = styled.div`
  margin: 60px 0;
  @media (min-width: ${breakpoints.min.lg}) {
    margin: 120px 0;
  }
`;

const Feedback: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <div className="mb-4 pb-2">
              <Heading1 large={true}>{t("feedback.haveYouTried")}</Heading1>
            </div>
            <div className="d-flex">
              <div className="d-none d-lg-block mr-4 pr-2">
                <Paper>
                  <img
                    src="/images/qr.png"
                    alt="QR code"
                    className="img-fluid"
                  />
                </Paper>
              </div>
              <GetApp storeType={StoreType.AppStore} />
            </div>
          </div>
          <div className="col-lg-5 mt-3 mt-lg-0">
            <Heading1 as="h3">{t("feedback.title")}</Heading1>
            <div className="mb-3"></div>
            <Paragraph>{t("feedback.description")}</Paragraph>
            <FeedbackForm />
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Feedback;
