import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import Heading2 from "../typo/heading2/heading2";
import styleVariables from "../../helpers/styleVariables";

const Root = styled.div`
  display: block;
  margin: 30px 0;
`;

const CarouselIndicators = styled.ol`
  padding: 0;
  margin: 32px 0 0;
  display: flex;
`;

interface CarouselIndicatorStylesProps {
  active: boolean;
}

const CarouselIndicator = styled.li<CarouselIndicatorStylesProps>`
  padding: 8px;
  list-style-type: none;
  cursor: pointer;
  &:hover::before {
    background: ${styleVariables.colors.blueRegular};
  }
  &::before {
    content: '';
    width: ${(props) => (props.active ? "16px" : "8px")};
    background: ${(props) =>
      props.active
        ? styleVariables.colors.blueRegular
        : styleVariables.colors.blueDark};
    height: 8px;
    border-radius: 8px;
    margin-right: 0px;
    display: block;
  
`;

const Carousel: React.FC = () => {
  const intervalRef = React.useRef<NodeJS.Timeout>();

  const { t } = useTranslation();

  const data = [
    {
      title: t("carousel.scanYourTarget"),
    },
    {
      title: t("carousel.recordYourFeelings"),
    },
    {
      title: t("carousel.shareYourResult"),
    },
  ];

  const [activeSlide, setActiveSlide] = React.useState<number>(0);

  React.useEffect(() => {
    const id = setInterval(() => {
      setActiveSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    }, 5000);
    intervalRef.current = id;
    return () => {
      // @ts-ignore
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleCarouselIndicatorClick = (index: number) => {
    setActiveSlide(index);
    // @ts-ignore
    clearInterval(intervalRef.current);
  };

  return (
    <Root>
      <div className="container">
        <div className="O-carousel">
          <div
            id="carouselExampleCaptions"
            className="carousel"
            data-ride="carousel"
          >
            <div className="carousel-inner" style={{ overflow: "visible" }}>
              <div className="carousel-item active">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    {data.map(({ title }, index) => {
                      return index === activeSlide ? (
                        <Heading2 key={title}>{title}</Heading2>
                      ) : (
                        <p key={title} className="A-paragraph -large my-0">
                          {title}
                        </p>
                      );
                    })}
                    <CarouselIndicators>
                      {data.map(({ title }, index) => (
                        <CarouselIndicator
                          key={title}
                          active={index === activeSlide}
                          onClick={() => handleCarouselIndicatorClick(index)}
                        />
                      ))}
                    </CarouselIndicators>
                  </div>
                  <div className="col-lg-8">
                    <img
                      src={`/images/carousel/phones/${activeSlide + 1}.png`}
                      srcSet={`/images/carousel/phones${
                        activeSlide + 1
                      }.png 1x, /images/carousel/phones${
                        activeSlide + 1
                      }@2x.png 2x`}
                      alt=""
                      loading="lazy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Carousel;
