import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";

const Root = styled.div``;

interface ButtonStylesProps {
  disabledStyle: boolean;
}

const Button = styled.a<ButtonStylesProps>`
  margin-top: 0.25rem;
  transform: scale(1);
  transition: 0.2s ease-in-out;
  display: block;
  cursor: ${(props) => (props.disabledStyle ? "default" : "pointer")};
`;

export enum StoreType {
  GooglePlay = "googlePlay",
  AppStore = "appStore",
}

interface GetAppProps {
  storeType: StoreType;
  centeredOnMobileView?: boolean;
}

const GetApp: React.FC<GetAppProps> = ({ storeType, centeredOnMobileView }) => {
  const { t } = useTranslation();
  const getComponentParams = (storeType: StoreType) => {
    if (storeType === StoreType.AppStore) {
      return {
        href: "https://apps.apple.com/app/surehand/id1523545945",
        imgSrc: "/images/app-store.svg",
        imgAlt: "App Store",
        text: t("getApp.freeInstallApps"),
      };
    }
    if (storeType === StoreType.GooglePlay) {
      return {
        href: "#",
        imgSrc: "/images/google-play.svg",
        imgAlt: "Google play",
        text: t("getApp.comingSoon"),
      };
    }
  };
  const componentParams = getComponentParams(storeType);
  if (!componentParams) {
    return null;
  }
  const disabled = storeType === StoreType.GooglePlay;
  return (
    <Root>
      <Paragraph
        size={ParagraphSize.small}
        className={`mb-0 ${centeredOnMobileView && "text-center text-lg-left"}`}
      >
        {componentParams.text}
      </Paragraph>
      <Button
        disabledStyle={disabled}
        as={disabled ? "span" : undefined}
        href={!disabled ? componentParams.href : undefined}
        target={!disabled ? "_blank" : undefined}
        rel="noopener"
      >
        <img src={componentParams.imgSrc} alt={componentParams.imgAlt} />
      </Button>
    </Root>
  );
};

export default GetApp;
