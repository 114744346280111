import type { NextPage } from "next";
import Advantages from "../shared/components/advantages/advantages";
import BgDecoration from "../shared/components/bgDecoration/bgDecoration";
import OurTeam from "../shared/components/ourTeam/ourTeam";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Feedback from "../shared/components/feedback/feedback";
import Carousel from "../shared/components/carousel/carousel";
import Download from "../shared/components/download/download";
import Experience from "../shared/components/experience/experience";
import NewFunctionalities from "../shared/components/newFunctionalities/newFunctionalities";
import Price from "../shared/components/price/price";
import Intro from "../shared/components/intro/intro";
import NativeAnchor from "../shared/components/nativeAnchor/nativeAnchor";
import { homepageAnchorIdentifiers } from "../shared/routes";
import Head from "next/head";
import React from "react";
import { useTranslation } from "next-i18next";
import LeaderboardsSection from "../shared/components/leaderboardsSection/leaderboardsSection";

const Home: NextPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t("meta.homepage.title")}</title>
        <meta name="description" content={t("meta.homepage.description")} />
        <meta property="og:title" content={t("meta.homepage.title")} />
        <meta
          property="og:description"
          content={t("meta.homepage.description")}
        />
        <meta property="og:image" content="/images/og/default.png" />
      </Head>
      <NativeAnchor id={homepageAnchorIdentifiers.home} />
      <section>
        <Intro />
      </section>
      <BgDecoration>
        <section>
          <Advantages />
        </section>
        <NativeAnchor id={homepageAnchorIdentifiers.features} />
        <section>
          <Carousel />
        </section>
        <NativeAnchor id={homepageAnchorIdentifiers.download} />
        <section>
          <Download />
        </section>
        <section>
          <Experience />
        </section>
        <section>
          <LeaderboardsSection />
        </section>
        <NativeAnchor id={homepageAnchorIdentifiers.price} />
        <section>
          <Price />
        </section>
      </BgDecoration>
      <BgDecoration>
        <NativeAnchor id={homepageAnchorIdentifiers.feedback} />
        <NewFunctionalities />
        <Feedback />
        <NativeAnchor id={homepageAnchorIdentifiers.team} />
        <OurTeam />
      </BgDecoration>
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
});

export default Home;
