import React from "react";
import styled from "@emotion/styled";

const Root = styled.div`
  top: -70px;
  position: relative;
`;

interface NativeAnchorProps {
  id: string;
}

const NativeAnchor: React.FC<NativeAnchorProps> = ({ id }) => {
  return <Root id={id}></Root>;
};

export default NativeAnchor;
