import React from "react";
import { useTranslation } from "next-i18next";
import { TextField } from "@mui/material";
import Button, { ButtonVariant } from "../button/button";
import FormAgreement from "../formAgreement/formAgreement";
import Box from "@mui/material/Box";
import ReCAPTCHA from "react-google-recaptcha";
import settings from "../../settings";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { mutateContactUs } from "../../api/mutations/contactUs";
import CircularProgress from "@mui/material/CircularProgress";

export const formSchema = z.object({
  yourEmail: z.string().email(),
  message: z.string().min(10),
});

const FeedbackForm: React.FC = () => {
  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(formSchema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isSuccess, isError, isLoading } =
    useMutation(mutateContactUs);
  const { t } = useTranslation();
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const onSubmit = async (data: any) => {
    if (recaptchaRef && recaptchaRef.current) {
      const recaptchaCode = await recaptchaRef.current.executeAsync();
      if (recaptchaCode) {
        const payload = {
          sender: data.yourEmail,
          subject: "Feedback",
          body: `${data.message}\nThis mail was sent from SureHand website.`,
          reCaptcha: recaptchaCode,
        };
        await mutate(payload);
      }
      await recaptchaRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("contactForm.success"));
      reset({
        yourEmail: "",
        message: "",
      });
    }
  }, [isSuccess, reset]);

  React.useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("contactForm.error"));
    }
  }, [isError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={settings.recaptchaKey}
      />
      <Controller
        name="yourEmail"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              label={t("feedback.form.yourEmail")}
              variant="filled"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              fullWidth
            />
          );
        }}
      />
      <Box mb={2} />
      <Controller
        name="message"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              label={t("feedback.form.message")}
              variant="filled"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              multiline
              rows={5}
              fullWidth
            />
          );
        }}
      />
      <Box mb={2} />
      <FormAgreement />
      {isLoading ? (
        <Box sx={{ display: "flex", height: 50 }} alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Button variant={ButtonVariant.primary} type="submit">
          {t("feedback.form.sendUsYourFeedback")}
        </Button>
      )}
    </form>
  );
};

export default FeedbackForm;
