import React from "react";
import { useTranslation } from "next-i18next";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import Button, { ButtonVariant } from "../button/button";
import FormAgreement from "../formAgreement/formAgreement";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import ReCAPTCHA from "react-google-recaptcha";
import settings from "../../settings";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useMutation } from "react-query";
import { mutateContactUs } from "../../api/mutations/contactUs";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";

export const formSchema = z.object({
  heatMaps: z.boolean().optional(),
  trainingGroups: z.boolean().optional(),
  onlineRankings: z.boolean().optional(),
  privateRankings: z.boolean().optional(),
  detailedStatistics: z.boolean().optional(),
  somethingDifferent: z.string().optional(),
});

const NewFunctionalitiesForm: React.FC = () => {
  const { control, handleSubmit, setError, reset } = useForm({
    resolver: zodResolver(formSchema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isSuccess, isError, isLoading } =
    useMutation(mutateContactUs);

  const { t } = useTranslation();
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);

  const onSubmit = async (data: any) => {
    if (
      !data.heatMaps &&
      !data.trainingGroups &&
      !data.onlineRankings &&
      !data.privateRankings &&
      !data.detailedStatistics &&
      !data.somethingDifferent
    ) {
      setError("somethingDifferent", {
        message: t("newFunctionalities.form.error.atLeastOne"),
      });
      return false;
    }
    if (recaptchaRef && recaptchaRef.current) {
      const recaptchaCode = await recaptchaRef.current.executeAsync();
      if (recaptchaCode) {
        let body = "";
        if (data.heatMaps) {
          body += `${t("newFunctionalities.form.heatMapsWithSlots")}\n`;
        }
        if (data.trainingGroups) {
          body += `${t("newFunctionalities.form.trainingGroups")}\n`;
        }
        if (data.onlineRankings) {
          body += `${t("newFunctionalities.form.onlineRankings")}\n`;
        }
        if (data.privateRankings) {
          body += `${t("newFunctionalities.form.privateRankings")}\n`;
        }
        if (data.detailedStatistics) {
          body += `${t("newFunctionalities.form.detailedStatistics")}\n`;
        }
        if (data.somethingDifferent) {
          body += `${data.somethingDifferent}\n`;
        }
        const payload = {
          sender: undefined,
          subject: "New functionalities",
          body: `${body}\nThis mail was sent from SureHand website.`,
          reCaptcha: recaptchaCode,
        };
        await mutate(payload);
      }
      await recaptchaRef.current.reset();
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("contactForm.success"));
      reset({
        heatMaps: undefined,
        trainingGroups: undefined,
        onlineRankings: undefined,
        privateRankings: undefined,
        detailedStatistics: undefined,
        somethingDifferent: "",
      });
    }
  }, [isSuccess, reset]);

  React.useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("contactForm.error"));
    }
  }, [isError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={settings.recaptchaKey}
      />
      <FormControl component="fieldset" variant="standard" fullWidth>
        <Controller
          name="heatMaps"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!value} />}
              label={t("newFunctionalities.form.heatMapsWithSlots") as string}
            />
          )}
        />
        <Box mt={-1.5} />
        <Controller
          name="trainingGroups"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!value} />}
              label={t("newFunctionalities.form.trainingGroups") as string}
            />
          )}
        />
        <Box mt={-1.5} />
        <Controller
          name="onlineRankings"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!value} />}
              label={t("newFunctionalities.form.onlineRankings") as string}
            />
          )}
        />
        <Box mt={-1.5} />
        <Controller
          name="privateRankings"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!value} />}
              label={t("newFunctionalities.form.privateRankings") as string}
            />
          )}
        />
        <Box mt={-1.5} />
        <Controller
          name="detailedStatistics"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!value} />}
              label={t("newFunctionalities.form.detailedStatistics") as string}
            />
          )}
        />
        <Controller
          name="somethingDifferent"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                label={t("newFunctionalities.form.somethingDifferent")}
                variant="filled"
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            );
          }}
        />
        <Box mb={2} />
      </FormControl>
      <FormAgreement />
      <Box display="flex">
        {isLoading ? (
          <Box sx={{ display: "flex", height: 50 }} alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Button variant={ButtonVariant.primary} type="submit">
            {t("newFunctionalities.form.sendUsYourVoteAndIdes")}
          </Button>
        )}
      </Box>
    </form>
  );
};

export default NewFunctionalitiesForm;
