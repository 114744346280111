import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import breakpoints from "../../helpers/breakpoints";
import Avatar from "../avatar/avatar";
import Heading2 from "../typo/heading2/heading2";
import Heading3 from "../typo/heading3/heading3";
import Heading4 from "../typo/heading4/heading4";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";
import Heading1 from "../typo/heading1/heading1";
import Button, { ButtonVariant } from "../button/button";
import { useRouter } from "next/router";
import Podium from "../podium/podium";
import { Box } from "@mui/system";

const Root = styled.div`
  margin: 65px 0 35px;
  @media (min-width: ${breakpoints.min.lg}) {
    margin: 130px 0 90px;
  }
`;
const LeaderboardsSection: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Root>
      <div className="container">
        <div className="mb-4 pb-2">
          <Heading1>{t("leaderboardsSection.title")}</Heading1>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <div className="mb-2" />
            <Paragraph>{t("leaderboardsSection.description")}</Paragraph>
          </div>
        </div>
        <div className="mb-4 pb-2">
          <Heading3>{t("leaderboardsSection.airPistol10m.title")}</Heading3>
          <Podium query={{ discipline: "10m_air_pistol" }} hideImage={true} />
          <div className="row">
            <div className="col-12 col-md-4 offset-md-4">
              <Box mt={0.25} mb={4} display="block">
                <Button
                  variant={ButtonVariant.ternary}
                  handleClick={() =>
                    router.push("leaderboards?discipline=10m_air_pistol")
                  }
                >
                  {t("leaderboardsSection.airPistol10m.seeAll")}
                </Button>
              </Box>
            </div>
          </div>
          <Heading3>{t("leaderboardsSection.airRifle10m.title")}</Heading3>
          <Podium query={{ discipline: "10m_air_rifle" }} hideImage={true} />
          <div className="row">
            <div className="col-12 col-md-4 offset-md-4">
              <Box mt={0.25} mb={4} display="block">
                <Button
                  variant={ButtonVariant.ternary}
                  handleClick={() =>
                    router.push("leaderboards?discipline=10m_air_rifle")
                  }
                >
                  {t("leaderboardsSection.airRifle10m.seeAll")}
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default LeaderboardsSection;
