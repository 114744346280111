import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import { useTranslation } from "next-i18next";
import Button, { ButtonVariant } from "../button/button";
import { homepageAnchorIdentifiers } from "../../routes";

const Root = styled.div`
  box-shadow: ${styleVariables.boxShadow.regular};
  background: ${styleVariables.colors.white};
  padding: 56px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const Line = styled.div`
  background: ${styleVariables.colors.blueRegular};
  height: 1px;
  display: inline-flex;
  margin: 16px 0;
`;

const BestPriceCircle = styled.div`
  background: ${styleVariables.colors.greenDark};
  border-radius: 50%;
  font-size: 18px;
  line-height: 1;
  color: ${styleVariables.colors.white};
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 82px;
  height: 82px;
  padding: 10px;
  text-align: center;
`;

const Title = styled.h3`
  color: ${styleVariables.colors.blueRegular};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;

  &.-large {
    margin-bottom: 14px;
    font-size: 24px;
  }
`;

const Price = styled.div`
  color: ${styleVariables.colors.blueDark};
  font-size: 48px;
  line-height: 1.07;
  margin: 6px -6px 0;
`;

const Small = styled.span`
  font-size: 18px;
`;

interface PriceBoxProps {
  bestPrice: boolean;
  title: string;
  bullets: string[];
  price: string;
  period: string;
  className: string;
}

const PriceBox: React.FC<PriceBoxProps> = ({
  bestPrice,
  title,
  bullets,
  price,
  period,
  className,
}) => {
  const { t } = useTranslation();
  const handleInstallAppClick = () => {
    document
      .getElementById(homepageAnchorIdentifiers.download)
      ?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className={`${className} col-lg-4 position-relative`}>
      <Root>
        {bestPrice ? (
          <div className="row">
            <div className="col-8">
              <Title className="M-priceBox__title -large">{title}</Title>
              <Price>
                {price} <Small> / {period}</Small>
              </Price>
            </div>
            <div className="col-4 mt-2">
              <BestPriceCircle>{t("price.yearly.bestPrice")}</BestPriceCircle>
            </div>
          </div>
        ) : (
          <>
            <Title>{title}</Title>
            <Price>
              {price} <Small>/ {period}</Small>
            </Price>
          </>
        )}
        <Line />
        <ul className="A-list">
          {bullets.map((bullet) => (
            <li key={bullet}>{bullet}</li>
          ))}
        </ul>
        <Button
          className="mt-2"
          variant={bestPrice ? ButtonVariant.secondary : ButtonVariant.primary}
          handleClick={() => {
            handleInstallAppClick();
          }}
          href={"#" + homepageAnchorIdentifiers.download}
        >
          {t("price.installApp")}
        </Button>
      </Root>
    </div>
  );
};

export default PriceBox;
