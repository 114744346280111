import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import styleVariables from "../../helpers/styleVariables";
import breakpoints from "../../helpers/breakpoints";
import GetApp, { StoreType } from "../getApp/getApp";
import Issf from "../issf/issf";
import Heading1, { Heading1Type } from "../typo/heading1/heading1";
import Heading2 from "../typo/heading2/heading2";

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 100px 0 0;
  position: relative;
  z-index: 1;
  background: ${styleVariables.colors.white} url(/images/intro-bg-mobile.svg)
    no-repeat;
  background-size: 160%;
  background-position: center bottom -1%;
  @media (min-width: ${breakpoints.min.sm}) {
    padding-bottom: 100px;
    background-size: auto;
    background-position: center top 110%;
  }
  @media (min-width: ${breakpoints.min.sm}) {
    padding-bottom: 100px;
    background-size: 800px;
    background-position: center top 100px;
  }
  @media (min-width: ${breakpoints.min.lg}) {
    padding: 200px 0;
    background: ${styleVariables.colors.white} url(/images/intro-bg.svg)
      no-repeat;
    background-size: auto 100%;
    background-position: center center;
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.max.sm}) {
    .mobilesOuter {
      overflow: hidden;
    }
  }
`;

const Mobiles = styled.img`
  width: 160%;
  height: auto;
  margin-top: 90px;
  margin-bottom: -30%;
  transform: translate(-17%, 0);
  @media (min-width: ${breakpoints.min.sm}) {
    width: 80%;
    margin-bottom: -30%;
    transform: translate(15%, -7%);
  }
  @media (min-width: ${breakpoints.min.lg}) {
    margin-bottom: -90%;
    width: 160%;
    transform: translate(-12%, -16%);
  }
`;

const Buttons = styled.div`
  display: flex;

  margin-top: 16px;
  @media (min-width: ${breakpoints.min.lg}) {
    margin-top: 73px;
  }
`;

const IssfOuter = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% - 60px);
  bottom: auto;
  transform: translateX(-50%);
  @media (min-width: ${breakpoints.min.lg}) {
    bottom: 80px;
    top: auto;
  }
`;

const Intro: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center">
            <div>
              <Heading1 type={Heading1Type.alternative} large>
                Surehand
                <br />
                my shooting app
              </Heading1>
              <div className="mb-3" />
              <Heading2>
                ISSF 10m air pistol & air rifle olympic discipline
              </Heading2>
              <Buttons>
                <GetApp
                  storeType={StoreType.AppStore}
                  centeredOnMobileView={true}
                />
                <div className="ml-3">
                  <GetApp
                    storeType={StoreType.GooglePlay}
                    centeredOnMobileView={true}
                  />
                </div>
              </Buttons>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 d-block align-items-end mobilesOuter">
            <Mobiles src="/images/intro-mobiles@2x.png" alt="" />
          </div>
        </div>
      </div>
      <IssfOuter>
        <Issf />
      </IssfOuter>
    </Root>
  );
};

export default Intro;
