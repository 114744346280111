import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import breakpoints from "../../helpers/breakpoints";
import Avatar from "../avatar/avatar";
import Heading2 from "../typo/heading2/heading2";
import Heading4 from "../typo/heading4/heading4";
import Paragraph, { ParagraphSize } from "../typo/paragraph/paragraph";

const Root = styled.div`
  margin: 65px 0 35px;
  @media (min-width: ${breakpoints.min.lg}) {
    margin: 130px 0 90px;
  }
`;

const Person = styled.div`
  display: flex;
  align-items: center;
`;

const PersonTextOuter = styled.div`
  margin-left: 20px;
  line-height: 0;
`;

const Experience: React.FC = () => {
  const { t } = useTranslation();

  const data = [
    {
      name: t("experience.dario.name"),
      position: t("experience.dario.position"),
      text: t("experience.dario.text"),
    },
    {
      name: t("experience.svetlana.name"),
      position: t("experience.svetlana.position"),
      text: t("experience.svetlana.text"),
    },
    {
      name: t("experience.arno.name"),
      position: t("experience.arno.position"),
      text: t("experience.arno.text"),
    },
  ];

  return (
    <Root>
      <div className="container">
        <div className="mb-4 pb-2">
          <Heading2>{t("experience.title")}</Heading2>
        </div>
        <div className="row">
          {data.map(({ name, position, text }, index) => (
            <div
              key={name}
              className={`col-lg-4 mb-4 pb-2 ${
                index === data.length - 1 && "d-none d-lg-block"
              }`}
            >
              <Person className="mb-3">
                <Avatar imgSrc={`/images/temp/avatar${index + 1}.jpg`} />
                <PersonTextOuter>
                  <Heading4 as="h3" bold className="my-0">
                    {name}
                  </Heading4>
                  <Paragraph className="my-0">{position}</Paragraph>
                </PersonTextOuter>
              </Person>
              <Paragraph size={ParagraphSize.large} className="mb-0">
                {text}
              </Paragraph>
            </div>
          ))}
        </div>
      </div>
    </Root>
  );
};

export default Experience;
