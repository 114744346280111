import * as z from "zod";
import { makeMutation } from "../api";
import { ApiEndpoints } from "../endpoints";

const ContactUsSchema = z.object({
  success: z.literal(true),
});

export interface ContactUsParams {
  sender?: string;
  subject: string;
  body: string;
  reCaptcha: string;
}

export const mutateContactUs = async (values: ContactUsParams) => {
  const response = await makeMutation(ApiEndpoints.contactUs(), values);
  return ContactUsSchema.parse(response);
};
