import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import styleVariables from "../../helpers/styleVariables";
import breakpoints from "../../helpers/breakpoints";
import Heading2 from "../typo/heading2/heading2";
import PriceBox from "../priceBox/priceBox";

const Root = styled.div`
  position: relative;
  padding-top: 20px;
  padding-bottom: 55px;

  @media (min-width: ${breakpoints.min.xl}) {
    padding-top: 40px;
  }

  &:after {
    @media (min-width: ${breakpoints.min.xl}) {
      content: "";
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${styleVariables.colors.beige};
      height: 260px;
    }
  }
`;

const Price: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <div className="O-price">
        <div className="container">
          <Heading2>{t("price.title")}</Heading2>
          <div className="row align-items-end">
            <PriceBox
              bestPrice={false}
              title={t("price.free.title")}
              bullets={[t("price.free.bullet1"), t("price.free.bullet2")]}
              price={t("price.free.price")}
              period={t("price.free.year")}
              className="z-3"
            />
            <PriceBox
              bestPrice={true}
              title={t("price.yearly.title")}
              bullets={[
                t("price.yearly.bullet1"),
                t("price.yearly.bullet2"),
                t("price.yearly.bullet3"),
              ]}
              price={t("price.yearly.price")}
              period={t("price.yearly.year")}
              className="mb-lg-2 z-2"
            />
            <PriceBox
              bestPrice={false}
              title={t("price.monthly.title")}
              bullets={[t("price.monthly.bullet1"), t("price.monthly.bullet2")]}
              price={t("price.monthly.pricePerMonth")}
              period={t("price.monthly.month")}
              className="z-1"
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Price;
