import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../../helpers/styleVariables";

interface Heading4Props {
  bold?: boolean;
  as?: React.ElementType;
  className?: string;
}

type Heading4StylesProps = Pick<Heading4Props, "bold">;

const Root = styled.h4<Heading4StylesProps>`
  font-size: ${styleVariables.fontSize.heading4FontSize};
  color: ${styleVariables.colors.grey};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

interface Heading4Props {
  bold?: boolean;
  as?: React.ElementType;
  className?: string;
}

const Heading4: React.FC<Heading4Props> = ({
  className,
  as,
  bold,
  children,
}) => {
  return (
    <Root as={as} bold={bold} className={className}>
      {children}
    </Root>
  );
};

export default Heading4;
