import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";

const Root = styled.div`
  display: flex;
  box-shadow: ${styleVariables.boxShadow.regular};
  background: ${styleVariables.colors.white};
  border-radius: 8px;
  flex-direction: column;
  width: 150px;
  align-items: center;
  text-align: center;
  padding: 16px 16px 26px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${styleVariables.colors.grey};
  font-weight: normal;
  line-height: 1.42;
  margin: 0 0 22px 0;
`;

const Logo = styled.img`
  width: 65px;
  height: auto;
`;

const Issf: React.FC = () => {
  return (
    <Root>
      <Description>ideal for training olympionic discipline</Description>
      <Logo src="/images/issf.svg" alt="ISSF" />
    </Root>
  );
};

export default Issf;
