import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";

interface PaperProps {
  large?: boolean;
}

const Root = styled.div<PaperProps>`
  box-shadow: ${styleVariables.boxShadow.regular};
  background: ${styleVariables.colors.white};
  border-radius: 8px;
  width: ${(props) => (props.large ? 230 : 160)}px;
  height: ${(props) => (props.large ? 230 : 160)}px;
  padding: ${(props) => (props.large ? 20 : 14)}px;
`;

const Paper: React.FC<PaperProps> = ({ large, children }) => {
  return <Root large={large}>{children}</Root>;
};

export default Paper;
