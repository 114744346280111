import React from "react";
import styled from "@emotion/styled";

interface AvatarProps {
  imgSrc: string;
  imgAlt?: string;
  large?: boolean;
}

type AvatarStylesProps = Pick<AvatarProps, "large">;

const Root = styled.div<AvatarStylesProps>`
  min-width: 60px;
  overflow: hidden;
  width: ${(props) => (props.large ? 160 : 60)}px;
  height: ${(props) => (props.large ? 160 : 60)}px;

  & img {
    border-radius: 50%;
  }
`;

const Picture = styled.img`
  width: 100%;
  height: auto;
`;

const Avatar: React.FC<AvatarProps> = ({ imgSrc, imgAlt, large }) => {
  return (
    <Root large={large}>
      <Picture src={imgSrc} alt={imgAlt || ""} />
    </Root>
  );
};

export default Avatar;
