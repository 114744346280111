import React from "react";
import styled from "@emotion/styled";
import styleVariables from "../../helpers/styleVariables";
import breakpoints from "../../helpers/breakpoints";
import { useTranslation } from "next-i18next";
import Icon from "../icon/icon";
import Heading3 from "../typo/heading3/heading3";
import Paragraph from "../typo/paragraph/paragraph";

const Root = styled.div`
  background: ${styleVariables.colors.beige};
  padding: 130px 0 44px 0;
  @media (min-width: ${breakpoints.min.lg}) {
    padding: 44px 0;
  }
`;

const Logo = styled.img`
  width: 65px;
  height: auto;
`;

interface Advantage {
  iconSrc: string;
  iconAlt?: string;
  heading: string;
  description: string;
}

const Advantages: React.FC = () => {
  const { t } = useTranslation();
  const data: Advantage[] = [
    {
      iconSrc: "/images/icons/shutcup.svg",
      heading: t("advantages.recordResults.title"),
      description: t("advantages.recordResults.description"),
    },
    {
      iconSrc: "/images/icons/heatmap.svg",
      heading: t("advantages.trackYourProgress.title"),
      description: t("advantages.trackYourProgress.description"),
    },
    {
      iconSrc: "/images/icons/time.svg",
      heading: t("advantages.saveYourTime.title"),
      description: t("advantages.saveYourTime.description"),
    },
    {
      iconSrc: "/images/icons/bullseye.svg",
      heading: t("advantages.improveYourSkills.title"),
      description: t("advantages.improveYourSkills.description"),
    },
  ];
  return (
    <Root>
      <div className="container">
        <div className="row">
          {data.map(({ iconSrc, iconAlt, heading, description }, index) => {
            return (
              <div
                key={heading}
                className={`col-lg-3 ${
                  index === 0 && "mb-4 pb-2 mb-lg-0 pb-lg-0"
                } ${index > 1 && "d-none d-lg-block"} `}
              >
                <Icon imgSrc={iconSrc} />
                <div className="mt-3 mb-2">
                  <Heading3>{heading}</Heading3>
                </div>
                <Paragraph className="mb-0">{description}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </Root>
  );
};

export default Advantages;
